@import '../_variables';
@import '../_macros';

.remotemenuimg {
    @media (min-width: 100px) and (max-width: 600px) {
        display: none;
    }
}

.home-three-image {
    background-color: $brandNavy;
    color: white;
    display: flex;

    @media (max-width: 1698px) {
        &>img {
            display: none;
        }
    }

    &>div:last-child {
        display: flex;
        justify-content: center;
        align-content: center;

        img {
            margin: auto;
            width: 100%;

            @media (min-width: 1401px) and (max-width: 1480px) {
                width: 80%;
            }

            @media (max-width: 1400px) {
                display: none;
            }
        }
    }

    .wrapper {
        @include make-default-widget-spacing();
        padding-top: 2em;
        padding-bottom: 2em;

        @media (max-width: 762px) {
            h1 {
                display: none;
            }

            ul {
                /*
				li {
					.btn {
						display: none;
					}
				}
				*/

                li:nth-child(1) {
                    //background-color: #6183a1;
                }

                li:nth-child(2) {
                    //background-color: #2b5880;
                }

                li:nth-child(3) {
                    //background-color: #174166;
                }
            }
        }

        &.wrapper {
            // Double specificity
            padding-right: 0px !important; // Override
        }

        a.btn {
            color: white;
            background-color: $brandBlue;
            border: 0.5px solid #88b7f8;
        }

        ul {
            list-style: none;

            display: flex;

            @include media-breakpoint-between(xs, lg) {
                flex-wrap: wrap;

                @at-root body.isIE11 & {
                    display: block;
                }
            }

            li {
                display: flex;
                flex-direction: column;
                margin: 2em;
                width: 300px;

                &:first-child {
                    margin-left: 0;
                }

                @include media-breakpoint-between(xs, lg) {
                    margin-left: 0;
                    width: 100%;

                    @at-root body.isIE11 & {
                        display: block;
                        width: auto;
                    }
                }

                .title {
                    color: $brandBlueOnBlueAccessable;
                    font-size: 1.3em;

                    a {
                        color: $brandBlueOnBlueAccessable;
                    }
                }

                .body {
                    flex: 1 1 auto;
                    font-size: 0.9em;
                }
            }
        }
    }
}

.macro-popular-courses {
    margin: 10px;
    display: none;

    .items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(90px, 1fr));

        .item {
            width: 100%;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 100%;
                height: 100%;

                max-height: 90px;
                max-width: 90px;

                @media (min-width: 414px) and (max-width: 762px) {
                    max-width: 100px;
                    max-height: 100px;
                }
            }

        }
    }

    h2 {
        font-size: 1em !important;
        color: #003057;
        font-size: 20px !important;
        font-weight: 500;
        background-color: white;
        padding: 13px;
    }

    @media (max-width: 762px) {
        display: block;
    }
}

.socialmediamobile {
    display: none;
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-top: 2em;
    padding-bottom: 2em;

    height: 150px;

    @media (max-width: 762px) {
        display: block;
    }

    h3.heading {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }

    a {
        div {
            padding-top: 9px;
            font-size: 25px;
            padding-left: 14px;
            width: 50px;
            height: 50px;
            margin-right: 20px;
            float: left;
            -webkit-box-shadow: -2px 2px 5px 0px #95b8d4;
            box-shadow: -2px 2px 5px 0px #95b8d4;
            border: 1px transparent;
            background-color: $brandNavy;
            color: white;

            i {
                text-align: center;
            }
        }

        .linkedin {
            background-color: #0e76a8;
        }

        .facebook {
            background-color: #4267b2;
            padding-left: 17px;
        }
    }
}

.macro-cities-mobile {
    margin: 10px;
    margin-bottom: 2rem;
    display: none;

    @media (max-width: 762px) {
        display: block;
    }

    h2 {
        padding: 0px 3px;
    }
}

.homedescription {
    .stars {
        display: none;
    }

    h1.homemobiletitle {
        font-size: 35px !important;
        font-weight: 1000;
    }

    .link {
        display: none;
    }

    .d-flex {
        display: none !important;

        @media (max-width: 762px) {
            display: flex !important;
            background-color: transparent;
        }

        background-color: $brandNavy;
        padding-bottom: 20px;
    }

    .mobilecourses {
        width: 100%;

        .button-container {
            margin-top: 22px;

            @media (max-width: 762px) {
                a {
                    padding: 5px 15px;
                }
            }
        }
    }
}

.macro-contact-us-mobile.topnavigation {
    display: none;
    height: 80px;

    @media (max-width: 762px) {
        display: block;
        margin-bottom: 45px;
    }

    .row {
        width: 95%;
        padding-left: 10px;
    }

    .glider-contain {

        * {
            z-index: 1 !important;
        }

        .glider-track {
            padding-bottom: 15px;
        }

        .glider-slide {
            min-width: 0;
        }

        .glider-prev,
        .glider-next {
            top: 25px;
            font-size: 25px;
        }

        .glider-prev {
            padding-left: 10px;
        }

        .glider-next {
            padding-right: 10px;
            right: -23px;
        }

        .glider-dots {
            padding-top: 10px;
        }
    }

    .tile {
        width: 77px;
        height: 75px;
        box-shadow: none;
        border: 1px solid #b5bbbe;
        background-color: #e5ebee;
    }

    img {
        padding-top: 10px;
        padding-bottom: 0px;
    }

    p {
        font-size: 14px;
    }

    h2 {
        font-size: 1em !important;
        color: #003057;
        font-size: 18px !important;
        font-weight: 500;
        background-color: white;
        padding: 15px;
    }

    .item {
        height: 80px;
    }
}

.title.mobilenav {
    display: none;

    @media (max-width: 762px) {
        display: block;
    }

    h2 {
        font-size: 1em !important;
        color: #003057;
        font-size: 20px !important;
        font-weight: 500;
        background-color: white;
        padding: 20px;
    }
}

section.macro-contact-us-mobile {
    display: none;
}

.citylinkmobile {

    .title h2 {
        color: $brandNavy;
        font-size: 20px !important;
        font-weight: 500;
        padding: 13px;
    }

    .city-links.items {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

        .item {
            padding: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: unset !important;

            .cityabbr {
                width: 80px;
                height: 80px;
                float: none;
            }
        }
    }
}
