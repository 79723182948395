@import "../variables";

*::-webkit-scrollbar {
    display: none;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.countrybanner {
    position: relative;
    z-index: 99999;

    #top-menu {
        padding-bottom: 0px !important;
    }

    a {
        color: white;

        :hover {
            color: white;
        }
    }

    .close {
        height: 30px;
        width: 30px;
        line-height: 30px;

        display: inline-block;
        padding-left: 10px;
        font-weight: 700;
        font-size: 20px;
        margin-left: 20px;
    }

    background-color: #7a7a7a;
    height: 50px;

    .selection {

        padding-bottom: 0px;

        p {
            margin-top: 10px;
            color: white;
            line-height: 30px;
        }

        @include media-breakpoint-between(xs, lg) {

            .extra {
                display: none;
            }
        }

        &.book-now.region a {
            width: 150px;

            @include media-breakpoint-between(xs, lg) {
                width: 90px;
            }

            padding: 5px 5px;
            margin-left: 5px;
            border: 1px solid white;

            img {
                width: 30px;
            }
        }

        ul.top {
            margin-left: 10px;
            display: block;
            top: 0px;
            right: 2em;

            &>li {
                min-width: initial;

                &>a {
                    color: white;
                    min-width: 100px;
                    text-align: center;
                    line-height: initial;
                    display: inline-block;
                    padding: 5px 20px;
                }

                .dropdown-container {
                    position: fixed;
                    z-index: 10;
                    flex-direction: column;
                    justify-content: flex-start;
                    min-height: initial;
                    background-color: white;
                    box-shadow: 3px 10px 17px -1px rgba(0, 0, 0, 0.75);
                    text-transform: lowercase;

                    a {
                        display: block;
                        margin-bottom: 15px;
                        color: white;
                        width: 150px;

                        &:last-child {
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }


        &>ul {
            display: flex;
            align-items: center;

            padding-left: 0;
            padding-bottom: 0;
            margin-bottom: 10px;

            &>li {
                min-width: 110px;
                font-size: 18px;
                display: inline-block;


                .expander-toggle {
                    display: none;
                }

                .expander-cat {
                    display: none;
                }

                &.book-now {
                    a {
                        width: 130px;
                        display: inline-block;
                        background-color: $brandBlue;
                        padding: 5px 30px;
                        margin-left: 5px;
                        border: 1px solid white;

                        &:hover {
                            background-color: darken($brandBlue, 4%);
                        }
                    }
                }


                &.book-now.region a {
                    min-width: 160px;

                    @include media-breakpoint-between(xs, lg) {
                        width: 90px;
                    }

                    padding: 5px 5px;
                    margin-left: 5px;
                    border: 1px solid white;

                    img {
                        width: 30px;
                    }
                }

                &.book-now.region {
                    height: 44px;

                    a {
                        min-width: 135px;
                        width: 100%;
                        padding: 5px 3px;
                        height: 32px;

                        span {
                            padding-left: 5px;
                            margin-right: 5px;
                            float: right;
                            font-weight: 500;
                            transform: rotate(90deg);
                        }
                    }

                    .dropdown-container {
                        padding-right: 8px;
                        padding-left: 16px;
                        background-color: white;

                        a {
                            background-color: white;
                            margin-left: 0px;
                            padding: 0px;

                            color: black;
                            text-transform: capitalize;

                            img {
                                padding-right: 10px;
                            }
                        }
                    }
                }


                &>a {
                    color: white;
                    padding: 0px 5px 5px 5px;

                    &:hover {
                        text-decoration: none;
                    }
                }

                .dropdown-container {
                    text-transform: none;
                    background-color: white;
                    color: $brandNavy;
                    min-height: 10em;
                    margin-top: 15px;

                    width: 100%;
                    display: none;
                    z-index: 999;
                    justify-content: center;

                    box-shadow: 1px 10px 13px -7px rgba(0, 0, 0, 0.75);

                    &.nosize {
                        width: auto;
                        padding: 1em;
                        margin-top: 0;
                    }

                    &.book-now {
                        a {
                            border-radius: 0;
                            margin: 1em;
                            width: 10em;
                        }
                    }

                    &.about-us {
                        flex-direction: column;
                        align-items: center;

                        .top {
                            display: flex;
                            width: 100%;
                        }

                        .bottom .btn {
                            margin: 1em 2em;
                        }
                    }

                    a {
                        color: $brandNavy;
                    }

                    [x-arrow] {
                        position: absolute;
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 0 10px 10px 10px;
                        border-color: transparent transparent #fff transparent;
                        margin-left: 18px;
                        top: -10px;
                    }

                }

            }

        }


        ul li {
            padding: 0.5em 0;



            .dropdown-container {
                display: none;

                a {
                    border-bottom: none;
                    font-weight: 400;
                }
            }

            .book-now.region {
                height: 44px;

                a {
                    min-width: 66px;
                    width: 90px;
                    padding: 5px 3px;
                    height: 32px;

                    span {
                        padding-left: 5px;
                        margin-right: 5px;
                        float: right;
                        font-weight: 500;
                        transform: rotate(90deg);
                    }
                }

                .dropdown-container {
                    padding-right: 8px;
                    padding-left: 16px;
                    background-color: white;

                    a {
                        background-color: white;
                        margin-left: 0px;
                        padding: 0px;

                        color: black;
                        text-transform: capitalize;

                        img {
                            padding-right: 10px;
                        }
                    }
                }
            }


        }


    }
}

.nav-link {
    color: $brandNavy;
    font-size: 1.2rem;
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    gap: 1rem;

    .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;

        img {
            width: 24px;
        }
    }

    &.root-link {
        color: $brandOrange !important;
    }

    &.course-link,
    &.course-cat-link {
        .logo {
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                width: 24px;
            }
        }

        span {
            flex-grow: 1;
            text-align: left;
        }
    }

}

.nav-icon {
    width: 24px;
    display: flex;
    justify-content: center;
    margin-right: 1rem;

    img {
        height: 24px;
    }
}

.topbar {
    position: relative;
    z-index: 30;
    background-color: $brandNavy;

    .banner-text {
        font-size: 14px;
        color: #fff;
    }

    .phone {
        font-weight: 600;
        display: flex;
        align-items: center;

        img {
            width: 20px;
            height: 20px;
            margin-right: 8px;
        }

        a {
            text-decoration: none;
            color: $brandNavy;
        }

        .container {
            display: flex;
            justify-content: end;
        }

    }
}

.btn-dropdown {
    position: relative;
    z-index: 99;

    .dropdown-container {

        .btn {
            z-index: -1;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    .dropdown-container-mobile {
        position: absolute;
        top: 113%;
        display: none;


        &.show {
            display: block;
        }

        .btn {
            background-color: white !important;
            color: $brandBlue !important;
            min-width: 120px !important;

            &:not(:first-of-type) {
                border-top: 1px solid #fff !important;
            }
        }
    }
}

#header {
    position: relative;
    z-index: 20;

    .header-wrapper {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 30;
        background-color: #fff;
        display: flex;
        align-items: center;
    }

    #desktop-nav-toggler {
        color: $brandBlue;
        font-size: 1.5rem;

        @include media-breakpoint-up(md) {
            margin-top: 2rem;
            font-size: 2rem;
        }
    }

    #mobile-nav-toggler {
        color: $brandBlue;
        font-size: 1.4rem;
    }

    .top-menu {
        background-color: $brandNavy;

        a {
            color: white;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;

            &:first-of-type::after {
                position: absolute;
                content: '';
                right: -2px;
                top: 10px;
                bottom: 10px;
                width: 2px;
                background-color: white;
            }
        }
    }

    @include media-breakpoint-down(sm) {

        .mobile-menu {

            gap: 0.2rem;

            li a {
                font-size: 16px;
                display: flex;
                align-items: center;
                color: $brandBlue !important;
                font-weight: normal !important;

                white-space: nowrap;
                overflow: hidden;

                i.icon {
                    font-size: 18px;
                }
            }
        }
    }


    &.header {
        background-color: #fff;
        height: 80px;

        @include media-breakpoint-up(md) {
            height: 130px;
        }

    }

    .home-link>img {
        width: 80px;
        height: auto;

        @include media-breakpoint-up(sm) {
            width: 100px;
        }

        @include media-breakpoint-up(md) {
            width: 140px;
        }

        @include media-breakpoint-up(lg) {
            width: 160px;
        }


    }
}

.city-list {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // text-align: start;

    .city-name {
        // font-weight: 600;
    }

    .address {
        font-size: 14px;
        font-weight: normal;
    }

    .callout {
        color: $brandBlue;
        font-size: 14px;
        font-weight: 600;
    }

    &:hover .callout {
        color: #fff;
    }
}

#desktop-nav {
    width: 100%;
    gap: 1rem;

    .nav-wrapper {
        @include media-breakpoint-up(md) {
            padding-top: 10px;
        }

        @include media-breakpoint-up(xl) {
            padding-top: 0;
        }
    }

    ul {
        gap: 0.4rem;

        &.top-nav>li>a.btn-link {
            color: $brandNavy;
            font-weight: 600;
        }

        li.navigation-link {
            // &:hover {
            //     .sub-nav {
            //         visibility: visible;
            //         opacity: 1;
            //         transition: all 0.2s;
            //     }
            // }

            a {
                text-decoration: none;
            }

            .sub-nav {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                bottom: 0;
                transform: translateY(100%);
                z-index: 99999;

                &.delay {
                    transition: all 0.2s 2s;
                }

                &.show {
                    visibility: visible;
                    opacity: 1;
                    transition: all 0.2s;
                }

                &::before {
                    position: absolute;
                    content: '';
                    top: -20px;
                    left: 0;
                    height: 50px;
                    width: 100px;
                }

                &::after {
                    position: absolute;
                    content: '';
                    top: -30px;
                    left: 20px;
                    width: 0;

                    border-left: 15px solid transparent;
                    border-right: 15px solid transparent;
                    border-top: 10px solid transparent;
                    border-bottom: 20px solid $brandNavy;
                }

                &-header {

                    background-color: $brandNavy;

                    ul {
                        display: flex;
                        gap: 0;

                        li {
                            position: relative;

                            a.header-link {
                                display: block;
                                box-sizing: content-box;
                                padding: 0.75rem 1.2rem;
                                color: #fff;

                                &.active {
                                    background-color: $brandOrange;
                                }
                            }

                            &:not(:last-of-type)::after {
                                position: absolute;
                                content: '';
                                right: 0;
                                top: 30%;
                                bottom: 30%;
                                width: 2px;
                                background-color: #fff;
                            }
                        }
                    }
                }

                &-body {
                    // max-height
                    // max-height: 450px;
                    // overflow-y: auto;

                    &::-webkit-scrollbar {
                        display: none;
                    }


                    background-color: #fff;
                    border-top: 10px solid $brandNavy;

                    &.no-border {
                        border: 0;
                    }

                    ul li a {
                        color: $brandNavy;
                        padding: 0.5rem 1.2rem;
                    }
                }

                .course-cat {
                    display: none;
                    background-color: #fff;

                    &.active {
                        display: block;
                    }
                }

                .course-cat li {
                    // disable
                    position: relative;

                    a {
                        color: $brandNavy;
                    }

                }

                .nav-link {
                    font-size: 1rem;

                    i {
                        font-size: 20px;
                        opacity: 100;
                        transition: opacity .3s ease-in-out;
                    }

                    &:hover,
                    &.active {
                        background-color: $brandBlue;
                        color: #fff;
                    }

                    &.active i {
                        opacity: 0;
                    }

                }

                .course-list {
                    position: absolute;
                    content: '';
                    right: 0;
                    top: 0;
                    background-color: $brandNavy;
                    transition: all .4s ease-in-out;
                    z-index: -1;
                    padding-top: 8px;
                    padding-bottom: 8px;
                    visibility: hidden;
                    opacity: 0;

                    &.show {
                        // display: block;
                        transform: translateX(100%);
                        visibility: visible;
                        opacity: 1;
                    }

                    li:first-of-type a::before {
                        position: absolute;
                        content: '';
                        top: 50%;
                        transform: translateY(-50%);
                        left: -1px;
                        border-left: 15px solid $brandBlue;
                        border-right: 15px solid transparent;
                        border-top: 10px solid transparent;
                        border-bottom: 10px solid transparent;
                    }

                    .nav-link {
                        padding-left: 32px;
                        padding-right: 24px;
                        padding-top: 4px !important;
                        padding-bottom: 4px !important;
                        // position: relative;
                        color: #fff;
                        gap: 1rem;
                        font-size: 14px;

                        &.root-link {
                            font-size: 1rem !important;
                            color: $brandOrange;
                            font-weight: bold;
                        }

                        .callout {
                            font-size: 14px;
                            color: $brandBlue;
                            font-weight: 600;
                        }

                        &:hover {
                            background-color: $brandNavy;
                            color: $brandOrange;
                        }
                    }

                }
            }
        }

        &.right-nav {

            padding-bottom: 1rem;

            li a {
                width: 100%;
                padding-left: 6px;
                padding-right: 6px;
                background-color: #fff;
                border: #fff;
                color: $brandNavy;
                font-weight: 600;

                &.dropdown-btn {
                    background-color: $brandNavy;
                    color: #fff;
                    transition: all .4s ease-in-out;

                    &:hover {
                        background-color: $brandBlue;
                    }
                }



                @include media-breakpoint-up(md) {
                    padding-left: 1rem;
                    padding-right: 1rem;
                    font-weight: 500;
                    width: 120px;

                    &:not(.dropdown-btn) {
                        color: #fff;
                        background-color: $brandBlue;
                    }
                }


                @include media-breakpoint-up(lg) {
                    padding-left: .5rem;
                    padding-right: .5rem;
                }
            }
        }
    }

    .search-input {
        width: 373px;
        position: relative;
        height: 36px;

        .search-text {
            height: 36px;
            font-size: 14px;
            width: 373px;
            padding: 5px 36px 5px 8px;
        }

        .icon {
            padding: 12px;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

    }

    .search-autocomplete {
        position: absolute;
        top: 100%;
        right: 0;
        left: 0;
        z-index: 100;
        display: flex;
        flex-direction: column;
        gap: 0;
        background-color: #fff;

        li {
            display: none;

            &.show {
                display: block;
            }

            a {
                display: block;
                padding: 4px 12px;
                color: $brandBlue;
                text-decoration: none;
                font-size: 14px;
            }

            &:hover {
                background-color: $brandBlue;

                a {
                    color: #fff;
                }
            }
        }
    }
}

#pay-dropdown {
    display: none;

    &.show {
        display: flex;
    }

    a {
        color: $brandNavy;
        font-weight: 500;
        background-color: #c8c8c8;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
    }
}

#mobile-menu-nav {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: #fff;
    transition: all 0.2s ease-in-out;
    z-index: 10;

    &.show {
        transform: translateY(100%);
    }

    .top-bar {
        height: 16px;
        background-color: $brandOrange;
    }

    .top-menu {
        position: relative;
        background-color: $brandNavy;

        a {
            color: white;
            padding-top: 10px;
            padding-bottom: 10px;
            position: relative;

            &:first-of-type::after {
                position: absolute;
                content: '';
                right: -1px;
                top: 10px;
                bottom: 10px;
                width: 2px;
                background-color: white;
            }
        }
    }

    .menu-link {
        color: $brandNavy;
        font-weight: 600;
        text-decoration: none;
        width: 100%;
        display: none;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        padding-top: 8px;
        padding-bottom: 8px;

        .menu-icon {
            width: 24px;
            display: flex;
            justify-content: center;
            align-items: center;

            img {
                height: 24px;
            }
        }

        .menu-title {
            flex: 1 1 auto;
            text-align: left;
        }

        .callout {
            color: $brandBlue;
            font-size: 14px;
            font-weight: 600;
        }

        &.show {
            display: flex;
        }
    }
}

#city-desktop .menu-link {
    color: $brandNavy;
    width: 100%;
    display: flex !important;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    gap: 1rem;

    &:hover,
    &.active {
        background-color: $brandBlue;
        color: #fff !important;

        .callout {
            color: #fff !important;
        }
    }

    .menu-icon {
        width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            height: 24px;
        }
    }

    .menu-title {
        flex: 1 1 auto;
        text-align: left;
    }

    .callout {
        color: $brandBlue;
        font-size: 14px;
        font-weight: 600;
    }

    &.show {
        display: flex;
    }
}

#menu-header {
    display: none;
    background-color: $brandBlue;

    &.show {
        display: block;
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
    }

    .menu-header-link {
        color: #fff;
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    .menu-title-wrapper {
        flex: 1 1 auto;
    }

    #menu-title {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
    }

}

.breadcrumb {
    background-color: $brandOrange;

    .breadcrumb-items {
        display: flex;
        margin-bottom: 0;

        li {
            &>a {
                color: white;
            }
        }

        .divider {
            padding: 0 10px;
            color: white;
        }
    }
}