@import '../_variables';

body > footer {
	$height: 320px;

	background-color: $brandBlueDarkest;

	@media (max-width: 762px) {
		background-color: #889da7;
	}
	min-height: $height;
	color: white;

	.wrapper {
		min-height: $height;
	}

	.logo-container {
		@media (max-width: 762px) {
			display: none;
		}
		background: $brandBlueDarkest url('./images/footerPattern.png') top left repeat;
		padding: 50px 24px 0px 0px;

		display: flex;
		justify-content: center;

		.logo {
			$width: 340px / 2;
			$height: 92px / 2;

			display: inline-block;
			width: $width;
			height: $height;
			background-size: $width $height;
			background-image: url('./images/logo-footer.png');

			background-repeat: no-repeat;
		}

		.taglines {
			margin-top: 20px;
			margin-bottom: 60px;

			div {
				line-height: 1.2;
				font-size: 27px;
			}
		}

		.iconround {
			display: inline-block;
			background-color: $brandBlue;
			border-radius: 100%;
			font-size: 34px;
			width: 60px;
			height: 60px;
			text-align: center;
			padding-top: 5px;
			margin-right: 10px;
		}
	}

	a {
		color: white;
	}

	.footerlinks {
		@include media-breakpoint-between(xs, md) {
			li {
				min-width: 44px;
				min-height: 44px;
				a {
					display: block;
					padding: 0px;
				}
			}
		}
	}
	.footer-content {
		padding: 70px 0px 0px 25px;

		@media (max-width: 762px) {
			padding: 25px 0px 0px 25px;
		}

		h4 {
			color: $brandBlueOnBlueAccessable;
			@media (max-width: 762px) {
				color: white;
			}
		}

		a {
			&:hover {
				color: white;
			}
		}

		ul {
			list-style-type: none;
			padding-left: 0;
		}

		& > .row > .col-md {
			flex-direction: column;
			align-content: center;
			align-items: center;
		}

		.row.first {
			@media (max-width: 762px) {
				.col-md:nth-child(1) {
					display: none;
				}

				.col-md:nth-child(2) {
					display: none;
				}
				.col-md {
					div {
						border-bottom: solid 1px;
						margin-bottom: 20px;
					}
				}
			}
			margin-right: 0;
			margin-left: 0;
		}

		@media (max-width: 762px) {
			p {
				color: #889da7;
			}
			.text-center {
				a {
					padding: 10px 10px;
					margin-left: 25%;
					border: 1px solid white;
					width: 150px;
					padding-left: center;
					text-align: center;
					line-height: initial;
				}
			}

			.searchbox {
				display: none;
			}

			.searchfooter {
				display: none;
			}
		}
	}
}
