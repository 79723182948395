@import "core/header.scss";
@import "core/footer.scss";
@import "core/main.scss";
@import "core/home.scss";
@import "core/courses.scss";
@import "core/content-page.scss";
@import "_macros.scss";
@import "_variables.scss";
@import "core/spinner.scss";

.default-side-padding{
	@include make-default-widget-spacing();
}

.default-top-padding{
	padding-top: 2em;
	padding-bottom: 2em;
}

.fixed-icon-container{
	display: inline-block;
	width: 120px;
}

.fixed-icon{
	width: (32px) * 1.5;
	height: (32px) * 1.5;
}

.text-left {
	text-align: left;
}

.flex-1 {
	flex: 1;
}

.rotate-90 {
	transform: rotate(90deg);
}

/*
.umb-grid{
	@include make-default-widget-spacing();
	padding-top: 2em;
	padding-bottom: 2em;
}
*/

.iframe-container{
	.spinner{
		display: none;
	}

	&.loading{
		min-height: 5em;

		.spinner{
			display: block;
		}
	}
}

.border-emboss {
	border: 1px solid #e3e3e3;
	-webkit-box-shadow: 0px 2px 3px 0px grey;
	box-shadow: 0px 2px 3px 0px grey;
}

.border-rounded {
	border-radius: 20px;
}

.main-wrapper {
	&.default-padding {
		section {
			padding: 20px 20px 20px 20px;

			@include media-breakpoint-up(lg) {
				padding: 60px 140px 60px 140px;
			}
		}
	}

	&.alternate-bg {
		&:nth-child(2n+2) {
			background-color: #F9F9F9;
		}
	}
}

.w-50-100 {
	width: 50%;

	@include media-breakpoint-down(lg) {
		width: 100%;
	}
}

.grecaptcha-badge {
	visibility: hidden;
}