@import '../_variables';
.page-type-courseCategory, .page-type-course {

    .course-details {
        display: none;

        &.selected {
            display: block;
        }

    }
}
