.long-list-grid{
	display: grid;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
		//grid-auto-rows: 20px;
		grid-gap: 5px;

		& > p {
			display: none;
		}

		// IE 11 reset back to columns
		@at-root body.isIE11 & {
			display: block;
			columns: 5;

			@include media-breakpoint-down(md) {
				columns: 3;
			}

			@include media-breakpoint-down(sm) {
				columns: 1;
			}
		}

		ul {
			//margin-left: 1.5em;

			li {
				//list-style: square inside url('data:image/gif;base64,R0lGODlhBQAKAIABAAAAAP///yH5BAEAAAEALAAAAAAFAAoAAAIIjI+ZwKwPUQEAOw==');
			}

			li:first-child {
				list-style: none;
				font-size: 14px;
				margin-left: -1em;
				position: relative;
			}

			font-size: 11px;
		}

}