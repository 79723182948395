@import '../_variables';

iframe {
    border: 0;
}

html {
    -webkit-overflow-scrolling: touch;
}

body>main {
    min-height: 10em;
}

.heading {
    font-size: 2.5rem;
    font-weight: 100;
}

.sitemap {
    padding-top: 10px;

    a {
        font-size: 20px;
    }
}

.extracontent {
    padding: 30px;

    @media (max-width: 762px) {
        padding: 0px 30px 0px 30px;
    }

    font-family: 'Interstate',
    Arial;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #003057;
    text-align: left;

    strong {
        display: list-item;
        list-style-type: disc;
        list-style-position: inside;
    }
}

.timeline {
    .timetittle {
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 60px;
        font-size: 2rem;
        display: block;
    }

    .col-sm {
        &:nth-child(1) {
            background-color: #0064f0;
        }

        &:nth-child(2) {
            background-color: #003057;
            color: #fff;
        }

        &:nth-child(3) {
            background-color: #ff3506;
        }

        &:nth-child(4) {
            background-color: #dbdbdb;
        }

        &:nth-child(5) {
            background-color: #595958;
            color: #fff;
        }

        &:nth-child(6) {
            background-color: #5497f5;
        }

        &:nth-child(7) {
            background-color: #0064f0;
        }
    }
}

.footer_links {
    @media (max-width: 575.98px) {
        min-width: 44px;
        min-height: 44px;
        margin-bottom: 0px;
        font-size: 1rem;
        display: block;
    }
}

.macro-contact-us-mobile {
    margin-top: 20px;
    margin-bottom: 20px;
    padding-left: 10px;

    @media (min-width: 414px) and (max-width: 762px) {
        padding-left: 17px;
    }

    h4 {
        font-size: 18px;
        font-weight: 500;
        padding-left: 20px;

        @media (min-width: 414px) and (max-width: 762px) {}

        padding-bottom: 10px;
        color: $brandNavy;
    }

    display: none;

    img {
        margin-left: auto;
        margin-right: auto;
        display: block;
        padding-top: 20px;
        padding-bottom: 10px;
    }

    .row {
        text-align: center;
        margin-right: 0px;
        margin-left: 0px;
    }

    .red {
        background-color: #ff5d38;
        color: white;
    }

    a {
        color: $brandNavy;
        text-decoration-line: none;
    }

    a:hover {
        text-decoration-line: none;
    }

    .col {
        padding: 0px;
    }

    .tile {
        width: 100px;
        height: 100px;
        -webkit-box-shadow: -2px 2px 5px 0px #95b8d4;
        box-shadow: -2px 2px 5px 0px #95b8d4;
        border-radius: 10px;
    }

    @media (max-width: 762px) {
        display: block;
        padding-bottom: 1em;
    }
}

.slick-track {
    margin: auto;
}

.slick-slider {

    .slick-prev:before,
    .slick-next:before {
        color: #003057;

        @media (max-width: 762px) {
            color: #fff;
        }
    }
}

.glider-contain {
    button.glider-next {
        right: 0;
    }
}

// OLD
/*
body{
    .blockquote-custom {
        position: relative;
        font-size: 1.1rem;
      }

      .blockquote-custom-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        top: -25px;
        left: 50px;
        color:$primary;
      }


      .arrow-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color:$primary;
        font-size: 20px;
        float:left;
        background-color: $primary;
      }

      .arrownav{
          clear:both;
      }

}
.tracking-in-contract {
    -webkit-animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
            animation: tracking-in-contract 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;
}

main{

    .section{
        @include media-breakpoint-between(xs, sm) {
            margin-top: 109px;
            .video-wrapper{
                display:none;
            }
        }
        margin-top:131px;

        .hero{
            background-color: $primary;
            color:#fff;
        }


    }

    .object {
        animation: shimmy 5s infinite;
    }

    .appscointainer{
        width: 350px;
        height: 230px;
        padding-top: 95px;
        padding-left: 162px;
        border-radius: 48%;
        margin: 0 auto;
        background-image: url('/media/c05jhcso/testheros.png');

    }

    .apps{
        position:relative;
        background-image : url('/media/55eohjjn/testapps.png');
        width: 150px;
        height: 65px;
        background-size: cover;
        background-position: 10px 0;
        background-repeat: repeat-x;
        animation: animate 10s linear infinite;

    }

    @keyframes animate{

        from{
            background-position:0 0;

        }
        to{
            background-position:400px 0;
        }


    }

    @-webkit-keyframes fly-cycle {
        100% {
        background-position: -900px 0;
       }
       }
    @keyframes fly-cycle {
        100% {
        background-position: -900px 0;
       }
    }

    @keyframes shimmy {
        0% {
          transform: translate(-20px, 0);
          opacity: 0;
        }
        25% {
            transform: translate(10px, 0);
            opacity: 1;
          }
        100% {
          transform: translate(40px, -50px);
          opacity: 0;
        }
      }


      .trustedby{
        padding-top: 60px;
        padding-bottom: 60px;
        background-color: #FDFEFE;

        h3{
        color: $primary;
        }

        img{
            .grayscale { filter: grayscale(100%); }
        }

        .slick-slide {
            margin: 0px 20px;

            img {
                width: 100%;
            }
        }


        .slide{

            img{
                max-width: 100%;
                max-height: 100%;
            }
        }


        .slick-slider
        {
            position: relative;
            display: block;
            box-sizing: border-box;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
                    user-select: none;
            -webkit-touch-callout: none;
            -khtml-user-select: none;
            -ms-touch-action: pan-y;
                touch-action: pan-y;
            -webkit-tap-highlight-color: transparent;
        }

        .slick-list
        {
            position: relative;
            display: block;
            overflow: hidden;
            margin: 0;
            padding: 0;

            &:focus
            {
                outline: none;
            }
        }

        .slick-list.dragging
        {
            cursor: pointer;
            cursor: hand;
        }

        .slick-slider .slick-track,
        .slick-slider .slick-list
        {
            -webkit-transform: translate3d(0, 0, 0);
               -moz-transform: translate3d(0, 0, 0);
                -ms-transform: translate3d(0, 0, 0);
                 -o-transform: translate3d(0, 0, 0);
                    transform: translate3d(0, 0, 0);
        }

        .slick-track
        {
            position: relative;
            top: 0;
            left: 0;
            display: block;

            &:before,
             &:after
            {
            display: table;
            content: '';
            }

              &:after
              {
            clear: both;
            }
        }


        .slick-loading .slick-track
        {
            visibility: hidden;
        }

        .slick-slide
        {
            display: none;
            float: left;
            height: 100%;
            min-height: 1px;
        }
        [dir='rtl'] .slick-slide
        {
            float: right;
        }
        .slick-slide img
        {
            display: block;
        }
        .slick-slide.slick-loading img
        {
            display: none;
        }
        .slick-slide.dragging img
        {
            pointer-events: none;
        }
        .slick-initialized .slick-slide
        {
            display: block;
        }
        .slick-loading .slick-slide
        {
            visibility: hidden;
        }
        .slick-vertical .slick-slide
        {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }
        .slick-arrow.slick-hidden {
            display: none;
        }




      }

    .trainingoptions{
        padding-top: 60px;
        padding-bottom: 60px;
        color:#fff;
        background-color: $fter_bgr;
        .container{
            .trainingdescription{
                padding-left:1.25rem;
            }
        }


        .card{

            .card-title{
                color:$primary;
            }
            .card-text{
                color:#fff;
            }
            background-color: $fter_bgr;
            border:none;

            .btn{
                border-radius: 0;
            }
        }



    }





    .trainwithus{

        background-color: #f3f8ff;

        h3{
            color:$primary;
        }

        .card{
            text-align: center !important;
            border:none;
            background-color: #f3f8ff;

            .card-title{
                color:$primary;
                font-size: 24px;
            }
            .card-text{
                font-weight: bold;
                font-size: 14px;
            }




         }

    }

    .nextstep{
       background-image : url('/media/05xh0z1i/bgsection.png');
       background-position: 750px 80px;
       background-repeat: no-repeat;
       background-size: 50%;
       @media screen and (max-width: 1540px) {
       background-position: 550px 80px;
       background-repeat: no-repeat;
       background-size: 70%;
       }


       @include media-breakpoint-between(sm, md) {
        background-position: 420px 80px;
        background-repeat: no-repeat;
        background-size: 100%;

       }


       @include media-breakpoint-between(xs, sm) {
        background-position: 0px 180px;
        background-repeat: no-repeat;
        background-size: 140%;

       }
        h3{
            color:$primary;
        }
        .square{
            border-radius: 0;
        }
        .container{
            .desc{
                width:100px;
            }
        }

        .card{
            width:250px;
            float:left;
            margin-right:35px;
            @media screen and (max-width: 1540px) {
                margin-right:10px;
            }
            .card-img-top {
                width: 100px;
                align-self: center;
                padding-top: 20px;
                padding-bottom: 10px;


            }


            .card-title{

                font-size: 16px;
            }
            .card-text{

                font-size: 13px;
            }




         }

    }




    .bg-info {
        background-color: #0064f0 !important;
    }

}

@-webkit-keyframes tracking-in-contract {
    0% {
      letter-spacing: 1em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: normal;
      opacity: 1;
    }
  }
  @keyframes tracking-in-contract {
    0% {
      letter-spacing: 1em;
      opacity: 0;
    }
    40% {
      opacity: 0.6;
    }
    100% {
      letter-spacing: normal;
      opacity: 1;
    }
  }



*/